.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.letter-row {
  width: 60px;
  height: 60px;
  border: 2px solid gray;
  display: inline-block;
  margin-right: 8px;
  font-size: 50px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  box-sizing: border-box;
  margin-bottom: 15px;
}

@keyframes guess {
  from {background-color: white;}
  to {background-color: green;}
}

@keyframes fail {
  from {background-color: white;}
  to {background-color: gray;}
}

@keyframes yell {
  from {background-color: white;}
  to {background-color: yell;}
}

.green-bg {
  background-color: green;
  animation-name: guess;
  animation-duration: 2s;
}

.yellow-bg {
  background-color: yellow;
  animation-name: yell;
  animation-duration: 2s;
}
.fail-bg {
  background-color: gray;
  animation-name: fail;
  animation-duration: 2s;
}

.container-main {
  margin-top: 60px;
  font-size: 30px;
}

.container-wordle {
  margin-top: 100px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 30%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  padding: 20px 0px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.keyboard-div {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  position: fixed;
  top: 75%;
  left: 20%;
}

@media only screen
and (max-device-width: 812px) {

  .keyboard-div {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: fixed;
    top: 75%;
    left: 0;
  }

  .modal-main {
    width: 80%;
  }

}

.hg-theme-default .hg-button.hg-standardBtn {
  width: auto !important;
  background-color: #d3d6da;
}

.hg-theme-default {
  background: none !important;
}

.close-button {
  background: none;
    border: none;
    margin-left: 88%;
    padding-right: 10px;
    padding-top: 5px;
}

.share-button {
  border: none;
    background-color: rgb(106, 170, 100);
    padding: 10px 30px;
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.share-button:hover {
  background-color: rgb(100, 160, 90);
}

.share-container {
  margin-top: 20px;
}
